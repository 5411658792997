import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { graphql, withPrefix } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import C from "../../colors";
import SEO from "../components/seo";
import LocalizedLink from "../components/localizedLink";
import { FormattedMessage } from "react-intl";

const BlogPage = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;

  .hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  @media (min-width: 850px) {
    .content-wrap {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      max-width: 75% !important;
    }
  }

  .content-wrap {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media (min-width: 1100px) {
    .content-wrap,
    .site-footer {
      margin-left: 0;
      margin-right: auto;
    }
  }

  @media (min-width: 700px) {
    .content-wrap {
      -webkit-transition: margin-right, margin-left 0.45s ease 75ms;
      transition: margin-right, margin-left 0.45s ease 75ms;
      margin-right: 0;
    }
  }

  .blogtitle {
    color: ${C.dark};
    font-size: 2.2rem;
    margin-top: 10px;
    padding-bottom: 8px;
    @media (max-width: 600px) {
      font-size: 2rem;
      line-height: 1.6;
      padding: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  /* pagination */

  .paginationList {
    display: flex;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 20px;
    margin-left: 0;
    list-style: none;
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 1.6;
      padding-top: 40px;
    }

    a {
      padding: 0.5em;
      margin-right: 0.3em;
      color: ${C.blue};
      font-size: 17px;

      &:hover {
        color: ${C.gray2};
      }
    }
  }

  /* pagination */

  .md {
    font-size: 17px;
    line-height: 1.75;
    color: ${C.dark};
    h1,
    h2,
    h3 {
      color: ${C.darker};
    }
    h1 {
      margin-bottom: 30px;
    }
    img {
      display: block;
      max-width: 100%;
      margin: 20px auto;
    }
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 1.6;
    }
  }
  .post-link {
    list-style: none;
    margin: 20px 0 50px;
    display: flex;
    .post-thumb {
    }
    .post-title {
    }

    a {
      font-size: 28px;
      color: ${C.darker};
    }
    img {
      float: left;
      max-width: 120px;
      margin-right: 20px;
      // margin-top: 10px;
      border-radius: 3px;
      cursor: pointer;
    }
    @media (max-width: 600px) {
      margin: 32px 0;
      img {
        max-width: 80px;
        margin-right: 15px;
        margin-top: 10px;
      }
      a {
        font-size: 150%;
      }
    }
  }
`;

const renderPagination = (currentPage, numPages) => {
  var rows = [];
  for (var i = 1; i <= numPages; i++) {
    rows.push(
      <PaginationItem disabled={i === currentPage} key={i}>
        <PaginationLink
          href={i === 1 ? withPrefix("/blog/") : withPrefix(`/blog/${i}/`)}
        >
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }
  return <Pagination aria-label="Blog navigation">{rows}</Pagination>;
};

const PostLink = props => (
  <section className="post-link" key={props.key}>
    {props.featuredImage.file.url && (
      <div className="post-thumb">
        <LocalizedLink to={`/blog/${props.slug}`}>
          <img alt={props.title} src={props.featuredImage.file.url} />
        </LocalizedLink>
      </div>
    )}
    {console.log(`https:${props.featuredImage.file.url}`)}
    <div className="post-title">
      <LocalizedLink to={`/blog/${props.slug}`}>{props.title}</LocalizedLink>{" "}
      <br />
      <span className="date">{props.date}</span>
    </div>
  </section>
);

const IndexPage = ({ data, pageContext }) => {
  let DATAPOST = data.posts.edges;
  let DATAIMG = data.fallbackImage.edges;

  //creating fallBack array that checks if images are missing in the localized content and fixes it
  let DATAQL = new Array();
  for (let i = 0; i < DATAPOST.length; i++) {
    let node = new Object();
    node["slug"] = DATAPOST[i].node.slug;
    node["title"] = DATAPOST[i].node.title;
    node["date"] = DATAPOST[i].node.date;
    node["node_locale"] = DATAPOST[i].node.node_locale;
    node["contentful_id"] = DATAPOST[i].node.contentful_id;

    if (DATAPOST[i].node.featuredImage.file !== null) {
      node["featuredImage"] = DATAPOST[i].node.featuredImage;
    } else {
      for (let j = 0; j < DATAIMG.length; j++) {
        if (DATAPOST[i].node.contentful_id === DATAIMG[j].node.contentful_id) {
          node["featuredImage"] = DATAIMG[j].node.featuredImage;
        }
      }
    }

    DATAQL.push(node);
  }

  let url = `https://www.eosgo.io/blog/`;
  let seoTitle;
  let seoDescription;

  const { currentPage, numPages, locale } = pageContext;


  //pagination and locale SEO 
  if (locale === "en-US") {
  if (currentPage === 1) {
    seoTitle = "Insights & Updates about EOS | EOS Go Blog";
    seoDescription =
      "The EOS Go Blog is your reference point for the most relevant insights of the entire EOS ecosystem. We feature industry updates, insider perspectives and in-depth DApp analysis.";
  } else {
    seoTitle = `Insights & Updates about EOS | EOS Go Blog page ${currentPage}`;
    seoDescription = `We are your reference point for the most relevant insights of the entire EOS ecosystem. We feature industry updates, insider perspectives and in-depth DApp analysis. | EOS Go Blog page ${currentPage}`;
  }
} else if (locale === "zh-CN") {
  if (currentPage === 1) {
    seoTitle = "EOS最新见解和新闻更新听取社区的声音 | EOS Go博客";
    seoDescription =
      "EOS Go博客是最权威EOS 新闻来源，是最了EOS生态系统的主流媒体。 关注EOS Go帮助你了解整个EOS生态系统，7×24小时EOS实时新闻快讯,了解EOS最新消息与价格行情走势。EOS Go柚子最新内容时时更新，内驻资深内部人士观点和深入的DApp分析，帮助区块链从业者和DApp跟进行业快讯。";
  } else {
    seoTitle = `EOS最新见解和新闻更新听取社区的声音 | EOS Go博客 第${currentPage}页`;
    seoDescription = `EOS Go博客是最权威EOS 新闻来源，是最了EOS生态系统的主流媒体。 关注EOS Go帮助你了解整个EOS生态系统，7×24小时EOS实时新闻快讯,了解EOS最新消息与价格行情走势。EOS Go柚子最新内容时时更新，内驻资深内部人士观点和深入的DApp分析，帮助区块链从业者和DApp跟进行业快讯。第${currentPage}页`;
  }
}

  return (
    <Layout title="Blog" locale={locale}>
      <BlogPage>
        <SEO title={seoTitle} description={seoDescription} />
        {/* <Helmet>
          <title key="title">Test</title>
          <meta key="description" name="description" content="etst" />
          <meta key="og:url" property="og:url" content={url} />
          <meta key="og:type" property="og:type" content="article" />
          <meta
            key="og:title"
            property="og:title"
            content="Blog | Insights & Updates about EOS"
          />
          <meta
            key="og:description"
            property="og:description"
            content="The EOS Go Blog is your reference point for the most relevant insights of the entire EOS ecosystem. We feature industry updates, insider perspectives and in-depth DApp analysis."
          />
          <meta
            key="og:image"
            property="og:image"
            content="https://www.eosgo.io/static/share.jpg"
          />
          <meta
            key="twitter:card"
            name="twitter:card"
            content="summary_large_image"
          />
          <meta key="twitter:site" name="twitter:site" content="@go_eos" />
          <meta
            name="twitter:image"
            content="https://www.eosgo.io/static/share.jpg"
          ></meta> */}

        {/* here we inject the JSON-LD from the function into a script tag  */}
        {/* <script
              type="application/ld+json"
              dangerouslySetInnerHTML={this.addJSONLDBlog()}
            /> */}

        {/* </Helmet> */}
        <h1 className="blogtitle"><FormattedMessage id="blog-title" /></h1>
        <br />

        {DATAQL.map((p, i) => {
          if (p != null) {
            return <PostLink {...p} key={i} />;
          }
        })}

        {numPages > 1 && renderPagination(currentPage, numPages)}
      </BlogPage>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query BlogPostsPageQueries($skip: Int!, $limit: Int!, $locale: String!) {
    posts: allContentfulPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
      filter: { slug: { ne: null }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          featuredImage {
            file {
              url
            }
          }
          title
          date
          node_locale
          contentful_id
        }
      }
    }
    fallbackImage: allContentfulPost(
      filter: { title: { ne: null }, node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          featuredImage {
            file {
              url
            }
          }
          node_locale
          contentful_id
        }
      }
    }
  }
`;
